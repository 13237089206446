// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import { ErrorCode } from './errorUtils';

// Check if the password matches the minimum requirement.
export const validatePassword = (password: string): boolean => {
  if (password.length < 8) {
    throw Error(ErrorCode.MinimumPasswordLength);
  }

  return true;
};
