// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.
import { t } from '../i18n/i18n';

export enum ErrorCode {
  UsernameRequired = 'UsernameRequired',
  EmailInvalid = 'EmailInvalid',
  PasswordRequired = 'PasswordRequired',
  InvalidLoginDetails = 'InvalidLoginDetails',
  InvalidEmailVerification = 'InvalidEmailVerification',
  AccountDoesNotExist = 'AccountDoesNotExist',
  MismatchAccountDetails = 'MismatchAccountDetails',
  TooManyRequests = 'TooManyRequests',
  Unknown = 'Unknown',
  MinimumPasswordLength = 'MinimumPasswordLength',
  TwoNewPasswordNotMatch = 'TwoNewPasswordNotMatch',
  NewPasswordEqualsOldPassword = 'NewPasswordEqualsOldPassword',
  PasswordUpdateFailed = 'PasswordUpdateFailed',
  LinkExpired = 'LinkExpired',
  NewUserLinkExpired = 'NewUserLinkExpired',
  EmailSendFailed = 'EmailSendFailed',
  MFACodeRequired = 'MFACodeRequired',
  InvalidMFACode = 'InvalidMFACode',
  SessionExpired = 'SessionExpired',
}

export const ErrorMessage: { [code in ErrorCode]: () => string } = {
  UsernameRequired: () =>
    t({
      defaultMessage: 'The username field is required.',
      id: 'nimUm5',
    }),
  EmailInvalid: () =>
    t({
      defaultMessage: 'The email provided is not a valid email address.',
      id: 'I+S0Wm',
    }),
  PasswordRequired: () =>
    t({
      defaultMessage: 'The Password field is required.',
      id: 'YKMYgr',
    }),
  InvalidLoginDetails: () =>
    t({
      defaultMessage: 'The email or password provided is incorrect.',
      id: '7bRp+J',
    }),
  InvalidEmailVerification: () =>
    t({
      defaultMessage: 'The email can not be verified.',
      id: 'y5hOCl',
    }),
  AccountDoesNotExist: () =>
    t({
      defaultMessage: 'You do not have an active account in this system. Please contact an administrator.',
      id: 'zJhjPm',
    }),
  MismatchAccountDetails: () =>
    t({
      defaultMessage: 'Login provider account details did not match supplied account details.',
      id: 'QuxyB4',
    }),
  TooManyRequests: () =>
    t({
      defaultMessage:
        'Your account has been locked temporarily due to too many failed attempts. Please try again later, or if you have forgotten your password, please use the "Having trouble logging in?" link.',
      id: 'kKLaAc',
    }),
  Unknown: () =>
    t({
      defaultMessage: 'Something went wrong. Please try again.',
      id: 'W/5hwr',
    }),
  MinimumPasswordLength: () =>
    t({
      defaultMessage: 'Password must be a minimum of 8 characters.',
      id: 'zzuJwO',
    }),
  PasswordUpdateFailed: () =>
    t({
      defaultMessage: 'Failed to update password. Please check the details you have entered.',
      id: 'Dc0sxp',
    }),
  LinkExpired: () =>
    t({
      defaultMessage:
        'The link has expired or is invalid. Please get a new recovery link by clicking the button below.',
      id: 'UYM8Xu',
    }),
  NewUserLinkExpired: () =>
    t({
      defaultMessage:
        'This link has expired. Please contact an administrator to reset your password or send a new link.',
      id: 'l/5oYO',
    }),
  TwoNewPasswordNotMatch: () =>
    t({
      defaultMessage: 'The new passwords do not match.',
      id: 'PSvELZ',
    }),
  NewPasswordEqualsOldPassword: () =>
    t({
      defaultMessage: 'The new password cannot be the same as the old password. Please choose a different one.',
      id: 'rti96f',
    }),
  EmailSendFailed: () =>
    t({
      defaultMessage: 'Failed to send the recovery link to your email. Please try again.',
      id: 'mEp5mn',
    }),
  MFACodeRequired: () =>
    t({
      defaultMessage: 'The code is required.',
      id: 'tX6Xoh',
    }),
  InvalidMFACode: () =>
    t({
      defaultMessage: 'The code you entered is incorrect. Please try again.',
      id: '00V+36',
    }),
  SessionExpired: () =>
    t({
      defaultMessage: 'Your session has expired. Please log in again.',
      id: 'MY+0QK',
    }),
};

export const getErrorMessage = (errorCode: ErrorCode | null): string | null => {
  if (!errorCode) return null;

  if (Object.keys(ErrorMessage).includes(errorCode)) {
    return ErrorMessage[errorCode as ErrorCode]();
  }

  return ErrorMessage['Unknown']();
};

// Get the error code from an error instance
export const getErrorCode = (err: unknown): ErrorCode => {
  if (err instanceof Error && Object.keys(ErrorMessage).includes(err.message)) {
    return err.message as ErrorCode;
  } else {
    return ErrorCode.Unknown;
  }
};
