// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import { Typography } from '@mui/material';
import { ErrorCode, getErrorMessage } from '../../../utils/errorUtils';

export const MismatchAccountDetails = () => {
  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        {getErrorMessage(ErrorCode.MismatchAccountDetails)}
      </Typography>
    </div>
  );
};
