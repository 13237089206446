// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

// IMPORTANT:
// Recommend to use extensions (e.g., Color Highlight) to help visualize the colors
// Color Selection and Management Guidelines:
// Prioritize searching for colors in this order:
//    a. SignatureColors
//    b. Other Color Objects
//
// Constant style guide: https://google.github.io/styleguide/jsguide.html#naming
// TLDR: All parents key using title case except the deepest child which uses all uppercase

// SignatureColors should contain all colors used across IMMT ID
// Prefer to use existing colors from SignatureColors before introducing new ones
// Smaller number means lighter shade
export const SignatureColors = {
  Yellow: {
    100: '#E1FFBD',
    200: '#EBDE8F',
    300: '#C6B280',
    400: '#FCE651',
    PRIMARY: '#FFC82C',
    600: '#bd9355',
    GOLD: '#AF9500',
    YELLOW_INPUT: 'rgba(255, 250, 161, 0.5)', // color with transparency
    PASTEL_YELLOW: '#fddd82',
    YELLOW_HIGHLIGHTER: '#FFFF00',
  },

  White: '#ffffff',

  Black: '#000000',

  Grey: {
    50: '#F3F3F3',
    75: '#EAEAEA',
    100: '#E6E6E6',
    150: '#DEDEDE',
    200: '#CFCFCF',
    250: '#B9B9B9',
    300: '#8D8D8D',
    350: '#757575',
    400: '#606060',
    600: '#4E4E4E',
    700: '#3F3F3F',
    INPUT_BORDER: 'rgba(0, 0, 0, 0.1)', // color with transparency
    BACKGROUND_COLOR: '#E7E7E8',
  },
};
