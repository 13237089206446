// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.
import Button from '@mui/material/Button';
import { Link, useSearchParams } from 'react-router-dom';
import { t } from '../../i18n/i18n';

const RedirectToLoginButton = ({ onClick }: { onClick?: () => void }) => (
  <Button type="button" color="info" variant="text" onClick={onClick}>
    {t({
      defaultMessage: 'Return to login',
      id: 'Le3MTe',
    })}
  </Button>
);

export const ReturnToLoginLink = () => {
  const [searchParams] = useSearchParams();

  // Redirect to the product if the csrfToken is absent and redirectTo is present.
  // This occurs when the user is redirected to the login page from the reset password page,
  // where the page is only accessible via the recovery link sent to the user's email.
  // In this case, the csrfToken is not sent to the user because it is not required for the password reset process.
  const shouldRedirectToProduct = !searchParams.has('csrfToken') && searchParams.has('redirectTo');

  const handleRedirect = () => {
    const redirectTo = searchParams.get('redirectTo');
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  };

  return shouldRedirectToProduct ? (
    <RedirectToLoginButton onClick={handleRedirect} />
  ) : (
    <Link to={{ pathname: `/`, search: searchParams.toString() }}>
      <RedirectToLoginButton />
    </Link>
  );
};
