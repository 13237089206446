// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { changeLanguage, Language } from '../i18n/i18n';
import { DEFAULT_LANGUAGE } from '../i18n/languages';
import ErrorPage from './Error/ErrorPage';
import { LoginForm } from './Login/LoginForm';
import { ForgotPassword } from './ForgotPassword/ForgotPassword';
import { ChangePassword } from './ChangePassword/ChangePassword';

import { ResetPassword } from './ResetPassword/ResetPassword';
import { MigratedUser } from './MigratedUserPage/MigratedUserPage';
import { SetupMFA } from './MFA/SetupMFA';
import { Profile } from './Profile/ProfilePage';
import { FAQ } from './FAQ/FAQ';

const initializeLanguage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const language = (searchParams.get('lang') as Language) || DEFAULT_LANGUAGE;
  changeLanguage(language);
};

const Routing: React.FC = () => {
  initializeLanguage();
  const [searchParams] = useSearchParams();

  return (
    <Routes>
      <Route path="/login" element={<LoginForm />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/migrated-account" element={<MigratedUser />} />
      <Route path="/mfa/setup" element={<SetupMFA />} />
      <Route path="/error/:errorCode" element={<ErrorPage />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/faq" element={<FAQ />} />

      {/* Capture Unknown routes */}
      <Route path="*" element={<Navigate to={{ pathname: '/login', search: searchParams.toString() }} />} />
    </Routes>
  );
};

export default Routing;
