// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.

import { Box, Button, DialogContent, DialogTitle, TextField } from '@mui/material';
import { t } from '../../../i18n/i18n';
import { useLoginFormContext } from '../LoginForm';
import { getErrorMessage } from '../../../utils/errorUtils';

const UsernamePage = () => {
  const { loginState, onUsernameChange, onContinue } = useLoginFormContext();

  return (
    <Box>
      <DialogTitle>
        {t({
          defaultMessage: 'Log in to Immersive Cloud',
          id: 'aJsppL',
        })}
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          autoFocus
          margin="dense"
          type="text"
          autoComplete="username"
          fullWidth
          error={loginState.errorCode !== null}
          helperText={getErrorMessage(loginState.errorCode)}
          value={loginState.username}
          placeholder={t({
            defaultMessage: 'Enter your email or username',
            id: 'JPVsz7',
          })}
          onChange={onUsernameChange}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              onContinue(ev);
            }
          }}
        />
        <Button type="submit" fullWidth onClick={onContinue} variant={'contained'} disableElevation>
          {t({
            defaultMessage: 'Continue',
            id: 'acrOoz',
          })}
        </Button>
      </DialogContent>
    </Box>
  );
};

export default UsernamePage;
