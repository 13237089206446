// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

export const SupportedFonts = ['Roboto', 'Helvetica', 'Arial', 'sans-serif'];

export const FontSizes = {
  XXSMALL: 8,
  XSMALL: 10,
  SMALL: 12,
  MEDIUM: 14,
  LARGE: 16,
  XLARGE: 18,
  XXLARGE: 20,
  XXXLARGE: 22,
};

export const FontWeights = {
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  BOLD: 700,
};
