// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Routing from './components/Routing';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Footer from './components/Common/Footer';
import * as Sentry from '@sentry/react';
import ErrorPage from './components/Error/ErrorPage';
import { initSentry } from './sentry';

initSentry();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Sentry.ErrorBoundary fallback={<ErrorPage />}>
    <React.StrictMode>
      <BrowserRouter>
        <Routing />
        <Footer />
      </BrowserRouter>
    </React.StrictMode>
  </Sentry.ErrorBoundary>
);
