// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import { Typography } from '@mui/material';
import { t } from '../../../i18n/i18n';
import { useSearchParams } from 'react-router-dom';
import { extractDomain } from '../../../utils/httpUtils';
import { SwitchAccountButton } from '../../Common/SwitchAccountButton';

export const AccountDoesNotExist = () => {
  const [searchParams] = useSearchParams();
  const domain = extractDomain(searchParams.get('redirectTo')!);

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        {t(
          {
            defaultMessage:
              'You do not have an account in {siteURL}. Please contact your Administrator to create an account.',
            id: 'VTjEyB',
          },
          { siteURL: domain }
        )}
      </Typography>

      <SwitchAccountButton />
    </div>
  );
};
