// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import { Alert, Box, Button } from '@mui/material';
import { t } from '../../../i18n/i18n';
import { Link, useSearchParams } from 'react-router-dom';
import { ErrorCode, getErrorMessage } from '../../../utils/errorUtils';

interface IAlertExpiredPageProps {
  isNewUserReset: boolean;
}

// This page indicates that the recovery link has expired.
// If the expiry is for new user creation, display a specific error message indicating that the user needs to contact an admin.
// If the expiry is for a regular password reset, show the standard expiry message.
// It also includes a button for users to return and resend the password reset email.
export const AlertExpiredPage = (props: IAlertExpiredPageProps) => {
  const [searchParams] = useSearchParams();
  const { isNewUserReset } = props;

  return (
    <Box id="alert-expired-link">
      <Alert severity="error">
        {getErrorMessage(isNewUserReset ? ErrorCode.NewUserLinkExpired : ErrorCode.LinkExpired)}
      </Alert>
      {!isNewUserReset && (
        <Link to={{ pathname: `/forgot-password`, search: searchParams.toString() }}>
          <Button fullWidth={true} variant="contained" color="primary">
            {t({
              defaultMessage: 'Get new recovery link',
              id: 'I7YRaA',
            })}
          </Button>
        </Link>
      )}
    </Box>
  );
};
