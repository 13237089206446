// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.

import { DEFAULT_LANGUAGE, LanguageSupported } from './languages';
import { createIntl, createIntlCache } from 'react-intl';
import esMessages from '../locale/es.json';
import enMessages from '../locale/en.json';
import frMessages from '../locale/fr.json';
import ptMessages from '../locale/pt.json';

function getMessages(selectedLocale: keyof typeof LanguageSupported): Record<string, string> {
  let messages: Record<string, { translation: string }> = {};

  switch (selectedLocale) {
    case 'en-AU':
      messages = enMessages.messages;
      break;
    case 'es-CL':
      messages = esMessages.messages;
      break;
    case 'fr-FR':
      messages = frMessages.messages;
      break;
    case 'pt-BR':
      messages = ptMessages.messages;
      break;
  }

  const mappedMessages: { [index: string]: string } = {};
  for (const key of Object.keys(messages)) {
    mappedMessages[key] = messages[key].translation;
  }

  return mappedMessages;
}

const cache = createIntlCache();
// Create the `intl` object
export let intl = createIntl(
  {
    locale: DEFAULT_LANGUAGE,
    defaultLocale: DEFAULT_LANGUAGE,
    messages: getMessages(DEFAULT_LANGUAGE),
  },
  cache
);

export let t = intl.formatMessage;

export const changeLanguage = (newLocale: Language): void => {
  intl = createIntl(
    {
      locale: newLocale,
      messages: getMessages(newLocale),
      defaultLocale: DEFAULT_LANGUAGE,
      onError: (err: any) => {
        if (err.code === 'MISSING_TRANSLATION') {
          console.warn('Missing translation', err.message);
          return;
        }
        throw err;
      },
    },
    cache
  );
  document.documentElement.lang = newLocale.toString();
  t = intl.formatMessage;
};

export type Language = keyof typeof LanguageSupported;
