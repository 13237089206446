// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

// checks if we are running a local instance of the code
export function isLocal() {
  return process.env.NODE_ENV === 'development';
}

export type AppConfig = {
  /** The URL of the api endpoint */
  serverUrl?: string;
};

const getAppConfig = async (): Promise<AppConfig> => {
  let config: AppConfig = {};
  const response = await fetch(`/config.json`);
  if (!response.ok) {
    throw new Error(`Failed to load configuration. Status: ${response.status}`);
  }
  config = await response.json();

  let { serverUrl } = config;
  if (isLocal()) {
    // For local development, we need to specify the server URL in the config file.
    if (!serverUrl) {
      throw new Error('Missing serverUrl in config.json');
    }
  } else {
    // For IMMT-ID, we deploy the server and the client using the same host.
    // In this case, we can use relative paths.
    serverUrl = '';
  }
  return Promise.resolve({
    serverUrl,
  });
};

export default getAppConfig;
