// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import CircularProgress from '@mui/material/CircularProgress';
import { t } from '../../i18n/i18n';

const LoadingPage = () => {
  return (
    <div style={{ display: 'flex', margin: 'auto' }}>
      <CircularProgress size={24} color="primary" />
      <span style={{ paddingLeft: '0.2rem' }}>
        {t({
          defaultMessage: 'Loading...',
          id: 'gjBiyj',
        })}
      </span>
    </div>
  );
};

export default LoadingPage;
