// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import * as Sentry from '@sentry/react';

function getSentryEnvironmentFromUrl() {
  const url = new URL(window.location.href);
  const domains = url.hostname.split('.');

  // This would be ["id", "immersivetechnologies", "cloud"]
  if (domains.length === 3) {
    return 'production';
  }

  // This would be ["id", "staging", "immersivetechnologies", "com"]
  if (domains.length === 4) {
    return domains[1];
  }

  if (url.hostname === 'localhost') {
    return 'local';
  }

  // This case/error is not supposed to happen.
  // Setting to production, the highest level, to be alerted of unhandled case.
  return 'production';
}

export const initSentry = () => {
  // Disable those third party services if the client is running on a local environment
  // which is in most cases using a localhost host.
  const enableSentry = !(window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1'));

  if (enableSentry) {
    Sentry.init({
      dsn: 'https://c4d4cd264660ee9ad88715947160953c@o38312.ingest.us.sentry.io/4508289463025664',
      release: `ImmersiveID@${(window as any).__APP_VERSION__}`,
      environment: getSentryEnvironmentFromUrl(),
      ignoreErrors: [
        // do not capture when there is a connection issue.
        /Failed to fetch/i,
      ],
      integrations: [Sentry.captureConsoleIntegration({ levels: ['error'] }), Sentry.browserTracingIntegration()],

      // Uncomment the below and enable sentry to test sentry locally,
      // without actually sending the event.
      // beforeSend(event, hint) {
      //   console.log(event);
      //   return null;
      // },
    });
  }
};
