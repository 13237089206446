// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import { Box, Button, DialogContent, DialogTitle, Link as MuiLink, TextField, Typography } from '@mui/material';
import { t } from '../../../i18n/i18n';
import { useLoginFormContext } from '../LoginForm';
import { getErrorMessage } from '../../../utils/errorUtils';
import { SwitchAccountButton } from '../../Common/SwitchAccountButton';
import { FontSizes } from '../../Themes/fonts';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_LANGUAGE } from '../../../i18n/languages';

const MFAPage = () => {
  const { loginState, onCodeSubmit, onCodeChange } = useLoginFormContext();
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') ?? DEFAULT_LANGUAGE;

  return (
    <Box>
      <DialogTitle>
        {t({
          defaultMessage: '2-Step Authentication',
          id: 'vsNhRD',
        })}
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">
          {t({
            defaultMessage: 'Enter the 6-digit code from your authenticator app.',
            id: 'RKK2dE',
          })}
        </Typography>
        <TextField
          variant="outlined"
          autoFocus
          margin="dense"
          placeholder={t({
            defaultMessage: 'Code',
            id: 'h2vipu',
          })}
          type="text"
          fullWidth
          error={loginState.errorCode !== null}
          helperText={getErrorMessage(loginState.errorCode)}
          value={loginState.pin}
          onChange={onCodeChange}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              onCodeSubmit(ev);
            }
          }}
        />

        <Button type="submit" fullWidth onClick={onCodeSubmit} variant={'contained'} disableElevation>
          {t({
            defaultMessage: 'Log in',
            id: 'odXlk8',
          })}
        </Button>

        <MuiLink
          href={`/faq?section=resetMFA&&lang=${lang}`}
          target="_blank"
          rel="noopener"
          color="inherit"
          underline="always"
          sx={{ fontSize: FontSizes.SMALL }}>
          {t({
            defaultMessage: 'Lost your device?',
            id: 's4GIdI',
          })}
        </MuiLink>

        <SwitchAccountButton color="secondary" />
      </DialogContent>
    </Box>
  );
};

export default MFAPage;
