// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.
import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import {
  Alert,
  Box,
  Button,
  CssBaseline,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import LoadingPage from '../Common/LoadingPage';
import { ErrorCode, getErrorCode, getErrorMessage } from '../../utils/errorUtils';
import { t } from '../../i18n/i18n';
import { updateCognitoCredentials } from '../../utils/authServiceUtils';
import { changePwdTheme } from '../Themes/Themes';
import Logo from '../Logo';

interface IChangePasswordState {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  isLoading: boolean;
  isSending: boolean;
  isSuccess: boolean;
  showOldPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  // General error in response to the server's reply, e.g., old password incorrect or unauthorized access.
  errorCode: ErrorCode | null;
}

// Interface for handling errors specific to each field in the change password form.
// Each property corresponds to an error that might occur in the respective section:
// - oldPasswordError: Error related to the old password.
// - newPasswordError: Error related to the new password (e.g., password too short).
// - confirmPasswordError: Error related to the confirmation of the new password (e.g., passwords do not match).
interface IError {
  oldPasswordError: ErrorCode | null;
  newPasswordError: ErrorCode | null;
  confirmPasswordError: ErrorCode | null;
}

export const ChangePassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const csrfToken = searchParams.get('csrfToken');
  const redirectTo = searchParams.get('redirectTo');
  const productName = searchParams.get('productName');

  const [state, setState] = useState<IChangePasswordState>({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    isLoading: false,
    isSending: false,
    isSuccess: false,
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    errorCode: null,
  });

  const [errors, setErrors] = useState<IError>({
    oldPasswordError: null,
    newPasswordError: null,
    confirmPasswordError: null,
  });

  useEffect(() => {
    if (!csrfToken || !redirectTo || !productName) {
      console.error('Missing necessary parameter');
      return navigate({ pathname: `/error/${ErrorCode.Unknown}`, search: searchParams.toString() });
    }
  }, []); // eslint-disable-line

  const handleChange = (prop) => (event) => {
    setState((prevState) => {
      const newState = { ...prevState, [prop]: event.target.value };
      validatePasswords(newState);
      return newState;
    });
  };

  const validatePasswords = (currentState) => {
    let newPasswordError: ErrorCode | null = null;
    let confirmPasswordError: ErrorCode | null = null;

    if (currentState.newPassword.length > 0 && currentState.newPassword.length < 8) {
      newPasswordError = ErrorCode.MinimumPasswordLength;
    }
    // Only show this error if both fields have been typed into
    if (currentState.confirmPassword.length > 0 && currentState.newPassword !== currentState.confirmPassword) {
      confirmPasswordError = ErrorCode.TwoNewPasswordNotMatch;
    }

    if (currentState.newPassword === currentState.oldPassword) {
      newPasswordError = ErrorCode.NewPasswordEqualsOldPassword;
    }

    setErrors({
      oldPasswordError: null,
      newPasswordError,
      confirmPasswordError,
    });
  };

  const handleSubmit = async () => {
    if (
      state.confirmPassword &&
      state.newPassword &&
      state.oldPassword &&
      !errors.newPasswordError &&
      !errors.confirmPasswordError
    ) {
      setState({ ...state, errorCode: null, isSuccess: false, isSending: true });

      try {
        await updateCognitoCredentials(state.oldPassword, state.newPassword);
        // Reset the form and show success message
        setState({
          ...state,
          isSuccess: true,
          errorCode: null,
          isSending: false,
          confirmPassword: '',
          newPassword: '',
          oldPassword: '',
        });
      } catch (err) {
        // Return to the login page if the session has expired
        const errorCode = getErrorCode(err);
        if (errorCode === ErrorCode.SessionExpired) {
          return navigate({ pathname: '/login', search: searchParams.toString() });
        } else {
          // Log unhandled errors
          console.error(`Failed to change password: ${(err as Error).message}`);
        }

        setState({ ...state, isSuccess: false, errorCode, isSending: false });
      }
    }
  };

  const handleTogglePasswordVisibility = (field) => {
    setState((prevState) => ({ ...prevState, [field]: !prevState[field] }));
  };

  return (
    <ThemeProvider theme={changePwdTheme}>
      <CssBaseline />
      <Dialog open fullWidth>
        {state.isLoading ? (
          <LoadingPage />
        ) : (
          <>
            <Logo />
            <DialogTitle>{t({ defaultMessage: `Change Password`, id: 'QiKKFz' })}</DialogTitle>
            <DialogContent>
              <Box component="form" noValidate autoComplete="off">
                <TextField
                  margin="dense"
                  label={t({
                    defaultMessage: 'Old Password',
                    id: 'J+buBa',
                  })}
                  type={state.showOldPassword ? 'text' : 'password'}
                  fullWidth
                  variant="outlined"
                  value={state.oldPassword}
                  onChange={handleChange('oldPassword')}
                  error={!!errors.oldPasswordError}
                  helperText={errors.oldPasswordError && getErrorMessage(errors.oldPasswordError)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleTogglePasswordVisibility('showOldPassword')}
                          edge="end">
                          {state.showOldPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="dense"
                  label={t({
                    defaultMessage: `Create New Password`,
                    id: 'kjOLpW',
                  })}
                  type={state.showNewPassword ? 'text' : 'password'}
                  fullWidth
                  variant="outlined"
                  value={state.newPassword}
                  onChange={handleChange('newPassword')}
                  error={!!errors.newPasswordError}
                  helperText={errors.newPasswordError && getErrorMessage(errors.newPasswordError)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleTogglePasswordVisibility('showNewPassword')}
                          edge="end">
                          {state.showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="dense"
                  label={t({
                    defaultMessage: `Confirm New Password`,
                    id: '4GbFNA',
                  })}
                  type={state.showConfirmPassword ? 'text' : 'password'}
                  fullWidth
                  variant="outlined"
                  value={state.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  error={!!errors.confirmPasswordError}
                  helperText={errors.confirmPasswordError && getErrorMessage(errors.confirmPasswordError)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleTogglePasswordVisibility('showConfirmPassword')}
                          edge="end">
                          {state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {state.isSuccess && (
                  <Alert severity="success">
                    {t({
                      defaultMessage: 'Password changed successfully!',
                      id: 'ahjI1k',
                    })}
                  </Alert>
                )}
                {state.errorCode && <Alert severity="error">{getErrorMessage(state.errorCode)}</Alert>}
                <Box>
                  <LoadingButton variant="contained" color="primary" onClick={handleSubmit} loading={state.isSending}>
                    {t({ defaultMessage: 'Submit', id: 'wSZR47' })}
                  </LoadingButton>
                  <Link to={{ pathname: '/profile', search: searchParams.toString() }}>
                    <Button variant="contained" color="secondary">
                      {t({ defaultMessage: 'Back', id: 'cyR7Kh' })}
                    </Button>
                  </Link>
                </Box>
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog>
    </ThemeProvider>
  );
};
