// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.
import Box from '@mui/material/Box';
import { Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import logoImg from '../assets/img/logo.png';

// Define the source and dimensions of the logo image for both default and small screen sizes
const logoData = {
  large: {
    src: logoImg,
    height: '78px',
    width: '300px',
  },
  small: {
    src: logoImg,
    height: '52px',
    width: '200px',
  },
  footer: {
    src: logoImg,
    height: '26px',
    width: '100px',
  },
};

const Logo = ({ footer }: { footer?: boolean }) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Determine the logo size based on the screen size and whether it is being used in the footer
  let logoConfig;
  if (footer) {
    logoConfig = logoData.footer;
  } else if (isSmallScreen) {
    logoConfig = logoData.small;
  } else {
    logoConfig = logoData.large;
  }
  const { src, height, width } = logoConfig;

  const handleOnLoad = () => {
    setLoaded(true);
  };

  const ImageWithSkeleton = (props: { src: string; alt?: string; width: string; height: string }) => {
    return (
      <Box>
        <img
          src={props.src}
          alt={props.alt}
          onLoad={handleOnLoad}
          style={{
            width: width,
            height: height,
            maxWidth: '100%',
            display: 'block', // Prevents extra space below the image
          }}
        />
        {!loaded && (
          <Skeleton
            variant="rectangular"
            sx={{
              width: width,
              height: height,
              maxWidth: '100%',
            }}
          />
        )}
      </Box>
    );
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      padding={footer ? 0 : theme.spacing(5)}>
      <ImageWithSkeleton src={src} width={width} height={height} />
    </Grid>
  );
};

export default Logo;
